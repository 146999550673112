export default {
    BOOK_TITLE: 'Practical Go Lessons',
    ROUTE_NAMES : {
        LEGAL: "Legal",
        THE_AUTHOR :"TheAuthor",
        FOREWORD: 'Foreword',
        HOME: 'Home',
        BUY : 'Buy',
        FEEDBACK : 'Feedback',
        SUCCESS : 'SUCCESS',
        CANCEL : "CANCEL",
        ASK_COUPON : "ASK_COUPON",
        PDF_PREVIEW : "PDF_PREVIEW",
        TERMS_CONDITIONS : "TERMS_CONDITIONS",
        CHANGELOG : "CHANGELOG",
        CHECKOUT_PAYPAL : "CHECKOUT_PAYPAL",
        SUBSCRIBE : "SUBSCRIBE",
        TUTORIAL : "TUTORIAL",
        LOGIN : "LOGIN",
        CART : "CART",
        PRODUCTS : "PRODUCTS",
        PRODUCT_DETAIL : "PRODUCT_DETAIL",
        CHECKOUT_LOGIN : "CHECKOUT_LOGIN",
        CHECKOUT_PAY : "CHECKOUT_PAY",
        REDEEM : "REDEEM",
        REDEEM_SUCCESS : "REDEEM_SUCCESS",
        MY_COURSES : "MY_COURSES",
        COURSE_DETAIL : "COURSE_DETAIL",
        LESSON_PREVIEW : "LESSON_PREVIEW",
        POST_DETAIL : "POST_DETAIL",
        POSTS : "POSTS",
        ERRATUM:"ERRATUM",
        UNSUBSCRIBE_NL:"UNSUBSCRIBE_NL",
        QUIZZ_VIEW : "QUIZZ_VIEW",
        CODE_COURSE : "CODE_COURSE"
    },
    CONTEXT_COURSE : "COURSE",
    BOOK_TYPES_ENUM : {
        HARD_BW :"HARD_BW",
        HARD_COLOR :"HARD_COLOR",
        DIGITAL: "DIGITAL",
        BUNDLE_BW: "BUNDLE_BW",
        BUNDLE_COLOR: "BUNDLE_COLOR"
    },
    BOOK_TYPE_DISPLAY : {
        HARD_BW: "Paper Copy in Black and White",
        HARD_COLOR: "Paper Copy in Color",
        BUNDLE_BW: "Paper Copy in Black and White + Digital",
        BUNDLE_COLOR: "Paper Copy in Color + Digital"

    },
    ISO_TO_PRICE : {
        "AD": {"EUR": "€9.15", "USD": "$11.75"},
        "AE": {"EUR": "€42.95", "USD": "$51.50"},
        "AF": {"EUR": "€42.95", "USD": "$51.50"},
        "AL": {"EUR": "€25.05", "USD": "$30.44"},
        "AM": {"EUR": "€25.05", "USD": "$30.44"},
        "AO": {"EUR": "€42.95", "USD": "$51.50"},
        "AQ": {"EUR": "€42.95", "USD": "$51.50"},
        "AR": {"EUR": "€42.95", "USD": "$51.50"},
        "AS": {"EUR": "€42.95", "USD": "$51.50"},
        "AT": {"EUR": "€18.05", "USD": "$22.21"},
        "AU": {"EUR": "€42.95", "USD": "$51.50"},
        "AX": {"EUR": "€18.05", "USD": "$22.21"},
        "AZ": {"EUR": "€25.05", "USD": "$30.44"},
        "BA": {"EUR": "€25.05", "USD": "$30.44"},
        "BD": {"EUR": "€42.95", "USD": "$51.50"},
        "BE": {"EUR": "€18.05", "USD": "$22.21"},
        "BF": {"EUR": "€42.95", "USD": "$51.50"},
        "BG": {"EUR": "€18.05", "USD": "$22.21"},
        "BH": {"EUR": "€42.95", "USD": "$51.50"},
        "BI": {"EUR": "€42.95", "USD": "$51.50"},
        "BJ": {"EUR": "€42.95", "USD": "$51.50"},
        "BL": {"EUR": "€20.30", "USD": "$24.87"},
        "BN": {"EUR": "€42.95", "USD": "$51.50"},
        "BO": {"EUR": "€42.95", "USD": "$51.50"},
        "BR": {"EUR": "€42.95", "USD": "$51.50"},
        "BY": {"EUR": "€25.05", "USD": "$30.44"},
        "CA": {"EUR": "€42.95", "USD": "$51.50"},
        "CC": {"EUR": "€42.95", "USD": "$51.50"},
        "CD": {"EUR": "€42.95", "USD": "$51.50"},
        "CF": {"EUR": "€42.95", "USD": "$51.50"},
        "CG": {"EUR": "€42.95", "USD": "$51.50"},
        "CH": {"EUR": "€18.05", "USD": "$22.21"},
        "CI": {"EUR": "€42.95", "USD": "$51.50"},
        "CK": {"EUR": "€42.95", "USD": "$51.50"},
        "CL": {"EUR": "€42.95", "USD": "$51.50"},
        "CM": {"EUR": "€42.95", "USD": "$51.50"},
        "CN": {"EUR": "€42.95", "USD": "$51.50"},
        "CO": {"EUR": "€42.95", "USD": "$51.50"},
        "CR": {"EUR": "€42.95", "USD": "$51.50"},
        "CW": {"EUR": "€42.95", "USD": "$51.50"},
        "CX": {"EUR": "€42.95", "USD": "$51.50"},
        "CY": {"EUR": "€18.05", "USD": "$22.21"},
        "CZ": {"EUR": "€18.05", "USD": "$22.21"},
        "DE": {"EUR": "€18.05", "USD": "$22.21"},
        "DJ": {"EUR": "€42.95", "USD": "$51.50"},
        "DK": {"EUR": "€18.05", "USD": "$22.21"},
        "DZ": {"EUR": "€25.05", "USD": "$30.44"},
        "EE": {"EUR": "€18.05", "USD": "$22.21"},
        "EG": {"EUR": "€42.95", "USD": "$51.50"},
        "ER": {"EUR": "€42.95", "USD": "$51.50"},
        "ES": {"EUR": "€18.05", "USD": "$22.21"},
        "ET": {"EUR": "€42.95", "USD": "$51.50"},
        "FI": {"EUR": "€18.05", "USD": "$22.21"},
        "FJ": {"EUR": "€42.95", "USD": "$51.50"},
        "FK": {"EUR": "€42.95", "USD": "$51.50"},
        "FM": {"EUR": "€42.95", "USD": "$51.50"},
        "FO": {"EUR": "€18.05", "USD": "$22.21"},
        "FR": {"EUR": "€9.15", "USD": "$11.75"},
        "GA": {"EUR": "€42.95", "USD": "$51.50"},
        "GB": {"EUR": "€21.05", "USD": "$25.75"},
        "GE": {"EUR": "€25.05", "USD": "$30.44"},
        "GF": {"EUR": "€20.30", "USD": "$24.87"},
        "GG": {"EUR": "€21.05", "USD": "$25.75"},
        "GH": {"EUR": "€42.95", "USD": "$51.50"},
        "GI": {"EUR": "€18.05", "USD": "$22.21"},
        "GL": {"EUR": "€18.05", "USD": "$22.21"},
        "GN": {"EUR": "€42.95", "USD": "$51.50"},
        "GP": {"EUR": "€20.30", "USD": "$24.87"},
        "GQ": {"EUR": "€42.95", "USD": "$51.50"},
        "GR": {"EUR": "€18.05", "USD": "$22.21"},
        "GS": {"EUR": "€42.95", "USD": "$51.50"},
        "GT": {"EUR": "€42.95", "USD": "$51.50"},
        "GU": {"EUR": "€42.95", "USD": "$51.50"},
        "HK": {"EUR": "€42.95", "USD": "$51.50"},
        "HN": {"EUR": "€42.95", "USD": "$51.50"},
        "HR": {"EUR": "€18.05", "USD": "$22.21"},
        "HU": {"EUR": "€18.05", "USD": "$22.21"},
        "ID": {"EUR": "€42.95", "USD": "$51.50"},
        "IE": {"EUR": "€18.05", "USD": "$22.21"},
        "IL": {"EUR": "€42.95", "USD": "$51.50"},
        "IM": {"EUR": "€21.05", "USD": "$25.75"},
        "IN": {"EUR": "€42.95", "USD": "$51.50"},
        "IO": {"EUR": "€42.95", "USD": "$51.50"},
        "IQ": {"EUR": "€42.95", "USD": "$51.50"},
        "IR": {"EUR": "€42.95", "USD": "$51.50"},
        "IS": {"EUR": "€25.05", "USD": "$30.44"},
        "IT": {"EUR": "€18.05", "USD": "$22.21"},
        "JE": {"EUR": "€21.05", "USD": "$25.75"},
        "JO": {"EUR": "€42.95", "USD": "$51.50"},
        "JP": {"EUR": "€42.95", "USD": "$51.50"},
        "KE": {"EUR": "€42.95", "USD": "$51.50"},
        "KG": {"EUR": "€42.95", "USD": "$51.50"},
        "KH": {"EUR": "€42.95", "USD": "$51.50"},
        "KI": {"EUR": "€42.95", "USD": "$51.50"},
        "KM": {"EUR": "€42.95", "USD": "$51.50"},
        "KP": {"EUR": "€42.95", "USD": "$51.50"},
        "KR": {"EUR": "€42.95", "USD": "$51.50"},
        "KW": {"EUR": "€42.95", "USD": "$51.50"},
        "KZ": {"EUR": "€42.95", "USD": "$51.50"},
        "LB": {"EUR": "€42.95", "USD": "$51.50"},
        "LI": {"EUR": "€18.05", "USD": "$22.21"},
        "LK": {"EUR": "€42.95", "USD": "$51.50"},
        "LS": {"EUR": "€42.95", "USD": "$51.50"},
        "LT": {"EUR": "€18.05", "USD": "$22.21"},
        "LU": {"EUR": "€18.05", "USD": "$22.21"},
        "LV": {"EUR": "€18.05", "USD": "$22.21"},
        "MA": {"EUR": "€25.05", "USD": "$30.44"},
        "MC": {"EUR": "€9.15", "USD": "$11.75"},
        "MD": {"EUR": "€25.05", "USD": "$30.44"},
        "ME": {"EUR": "€42.95", "USD": "$51.50"},
        "MF": {"EUR": "€20.30", "USD": "$24.87"},
        "MG": {"EUR": "€42.95", "USD": "$51.50"},
        "MH": {"EUR": "€42.95", "USD": "$51.50"},
        "MK": {"EUR": "€25.05", "USD": "$30.44"},
        "ML": {"EUR": "€42.95", "USD": "$51.50"},
        "MO": {"EUR": "€42.95", "USD": "$51.50"},
        "MP": {"EUR": "€42.95", "USD": "$51.50"},
        "MQ": {"EUR": "€20.30", "USD": "$24.87"},
        "MT": {"EUR": "€18.05", "USD": "$22.21"},
        "MU": {"EUR": "€42.95", "USD": "$51.50"},
        "MV": {"EUR": "€42.95", "USD": "$51.50"},
        "MX": {"EUR": "€42.95", "USD": "$51.50"},
        "MY": {"EUR": "€42.95", "USD": "$51.50"},
        "NA": {"EUR": "€42.95", "USD": "$51.50"},
        "NC": {"EUR": "€31.50", "USD": "$38.04"},
        "NE": {"EUR": "€42.95", "USD": "$51.50"},
        "NF": {"EUR": "€42.95", "USD": "$51.50"},
        "NG": {"EUR": "€42.95", "USD": "$51.50"},
        "NL": {"EUR": "€18.05", "USD": "$22.21"},
        "NO": {"EUR": "€25.05", "USD": "$30.44"},
        "NP": {"EUR": "€42.95", "USD": "$51.50"},
        "NR": {"EUR": "€42.95", "USD": "$51.50"},
        "NZ": {"EUR": "€42.95", "USD": "$51.50"},
        "OM": {"EUR": "€42.95", "USD": "$51.50"},
        "PA": {"EUR": "€42.95", "USD": "$51.50"},
        "PF": {"EUR": "€31.50", "USD": "$38.04"},
        "PG": {"EUR": "€42.95", "USD": "$51.50"},
        "PH": {"EUR": "€42.95", "USD": "$51.50"},
        "PK": {"EUR": "€42.95", "USD": "$51.50"},
        "PL": {"EUR": "€18.05", "USD": "$22.21"},
        "PM": {"EUR": "€20.30", "USD": "$24.87"},
        "PN": {"EUR": "€42.95", "USD": "$51.50"},
        "PT": {"EUR": "€18.05", "USD": "$22.21"},
        "PW": {"EUR": "€42.95", "USD": "$51.50"},
        "QA": {"EUR": "€42.95", "USD": "$51.50"},
        "RE": {"EUR": "€20.30", "USD": "$24.87"},
        "RO": {"EUR": "€18.05", "USD": "$22.21"},
        "RS": {"EUR": "€25.05", "USD": "$30.44"},
        "RU": {"EUR": "€42.95", "USD": "$51.50"},
        "RW": {"EUR": "€42.95", "USD": "$51.50"},
        "SA": {"EUR": "€42.95", "USD": "$51.50"},
        "SB": {"EUR": "€42.95", "USD": "$51.50"},
        "SD": {"EUR": "€42.95", "USD": "$51.50"},
        "SE": {"EUR": "€18.05", "USD": "$22.21"},
        "SG": {"EUR": "€42.95", "USD": "$51.50"},
        "SH": {"EUR": "€42.95", "USD": "$51.50"},
        "SI": {"EUR": "€18.05", "USD": "$22.21"},
        "SK": {"EUR": "€18.05", "USD": "$22.21"},
        "SM": {"EUR": "€18.05", "USD": "$22.21"},
        "SN": {"EUR": "€42.95", "USD": "$51.50"},
        "SR": {"EUR": "€42.95", "USD": "$51.50"},
        "SS": {"EUR": "€42.95", "USD": "$51.50"},
        "ST": {"EUR": "€42.95", "USD": "$51.50"},
        "SV": {"EUR": "€42.95", "USD": "$51.50"},
        "SZ": {"EUR": "€42.95", "USD": "$51.50"},
        "TD": {"EUR": "€42.95", "USD": "$51.50"},
        "TF": {"EUR": "€31.50", "USD": "$38.04"},
        "TG": {"EUR": "€42.95", "USD": "$51.50"},
        "TH": {"EUR": "€42.95", "USD": "$51.50"},
        "TJ": {"EUR": "€42.95", "USD": "$51.50"},
        "TL": {"EUR": "€42.95", "USD": "$51.50"},
        "TM": {"EUR": "€42.95", "USD": "$51.50"},
        "TN": {"EUR": "€25.05", "USD": "$30.44"},
        "TO": {"EUR": "€42.95", "USD": "$51.50"},
        "TR": {"EUR": "€25.05", "USD": "$30.44"},
        "TT": {"EUR": "€42.95", "USD": "$51.50"},
        "TV": {"EUR": "€42.95", "USD": "$51.50"},
        "TW": {"EUR": "€42.95", "USD": "$51.50"},
        "TZ": {"EUR": "€42.95", "USD": "$51.50"},
        "UA": {"EUR": "€25.05", "USD": "$30.44"},
        "UG": {"EUR": "€42.95", "USD": "$51.50"},
        "US": {"EUR": "€42.95", "USD": "$51.50"},
        "VA": {"EUR": "€18.05", "USD": "$22.21"},
        "VC": {"EUR": "€42.95", "USD": "$51.50"},
        "VG": {"EUR": "€42.95", "USD": "$51.50"},
        "VI": {"EUR": "€42.95", "USD": "$51.50"},
        "VN": {"EUR": "€42.95", "USD": "$51.50"},
        "VU": {"EUR": "€42.95", "USD": "$51.50"},
        "WF": {"EUR": "€31.50", "USD": "$38.04"},
        "WS": {"EUR": "€42.95", "USD": "$51.50"},
        "YT": {"EUR": "€20.30", "USD": "$24.87"},
        "ZA": {"EUR": "€42.95", "USD": "$51.50"},
        "ZM": {"EUR": "€42.95", "USD": "$51.50"},
        "ZW": {"EUR": "€42.95", "USD": "$51.50"}
    },
    CONTACT_FORM_CONTEXT : {
        ORDER_HARD_COPY_BW: "orderHardCopyBW",
        ORDER_HARD_COPY_COL: "orderHardCopyCOL",
        ORDER_DIGITAL_COPY: "orderDigitalCopy",
        FEEDBACK: "feedback",
        COUPON: "coupon"

    },
    MAIN_PRICES: {
        "DIGITAL": {
            "EUR": "€39",
            "USD": "$49",
        },
        "HARD_BW": {
            "EUR": "€59",
            "USD": "$69",
        },
        "HARD_COLOR": {
            "EUR": "€69",
            "USD": "$79",
        },
        "DELTA":{
            "EUR": "€10",
            "USD": "$10",
        },
        "BUNDLE_BW": {
            "EUR": "€79",
            "USD": "$89",
        },
        "BUNDLE_COLOR": {
            "EUR": "€89",
            "USD": "$99",
        },

    }
}