import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import router from './router'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMeta from 'vue-meta'
// vue validation
import { ValidationProvider } from 'vee-validate';
import { extend } from 'vee-validate';
import { required, email, max, min } from 'vee-validate/dist/rules';
import "bootstrap-icons/font/bootstrap-icons.css";
import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";

import firebase from "firebase/compat/app"
// swiper https://swiperjs.com/
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import Swiper styles
import 'swiper/css/swiper.css'
import 'maz-ui/lib/css/base.css'
import 'maz-ui/lib/css/maz-phone-number-input.css'

import { MazPhoneNumberInput } from 'maz-ui'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig)

// Add vee validate rules
// Add the required rule
extend('required', required);
// Add the email rule
extend('email',email);
extend('max',max);
extend('min',min);

// swiper
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(MazPhoneNumberInput)
//import FirebaseConf from "@/firebaseConf";
//import firebase from 'firebase/app'
//var firebaseui = require('firebaseui');
//import 'firebase/auth';

//firebase.initializeApp(FirebaseConf.conf);
//Vue.prototype.$firebaseUi = new firebaseui.auth.AuthUI(firebase.auth());

// SCSS libs
require('@/assets/main.scss');
Vue.config.productionTip = false
Vue.use(BootstrapVue)
import 'highlight.js/styles/default.css';
import VueHighlightJS from '@/vueHighlight'
// Tell Vue.js to use vue-highlightjs
Vue.use(VueHighlightJS)

new Vue({
  router,
  components: {
    ValidationProvider
  },
  render: h => h(App)
}).$mount('#app')
