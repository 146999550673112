

function loadCrisp() {
    return new Promise(function(resolve, reject){
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID;
        let src = "https://client.crisp.chat/l.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}


function loadStripe() {
    return new Promise(function(resolve, reject){
        let src = "https://js.stripe.com/v3/";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}

function loadStream() {
    return new Promise(function(resolve, reject){
        let src = "https://embed.videodelivery.net/embed/sdk.latest.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}
function loadPaypal(currency) {
    return new Promise(function(resolve, reject){
        let src = "https://www.paypal.com/sdk/js?currency="+currency+"&locale=en_US&client-id="+process.env.VUE_APP_PAYPAL_CLIENT_ID;
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.setAttribute('data-namespace', "paypal-"+currency);
            el.type = 'text/javascript';
            //el["data-order-id"] = orderId
            //el["data-page-type"] = "checkout"
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}

// <!-- Google tag (gtag.js) -->
//       <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10991349756"></script>
//       <script>
//         window.dataLayer = window.dataLayer || [];
//         function gtag(){dataLayer.push(arguments);}
//         gtag('js', new Date());
//
//         gtag('config', 'AW-10991349756');
//       </script>
function loadGtag(){
    return new Promise(function(resolve, reject){
        let src = "https://www.googletagmanager.com/gtag/js?id=AW-10991349756";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.setAttribute('data-namespace', "gtag");
            el.type = 'text/javascript';
            //el["data-order-id"] = orderId
            //el["data-page-type"] = "checkout"
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}

function loadRedditPixel(){
    return new Promise(function(resolve, reject){
        let src = "https://www.redditstatic.com/ads/pixel.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.setAttribute('data-namespace', "reddit");
            el.type = 'text/javascript';
            //el["data-order-id"] = orderId
            //el["data-page-type"] = "checkout"
            el.async = !0;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            // init logic
            console.log("r pixel init")
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })

}


export default {
    loadCrisp:loadCrisp,
    loadStripe:loadStripe,
    loadPaypal:loadPaypal,
    loadStream:loadStream,
    loadGtag:loadGtag,
    loadRedditPixel:loadRedditPixel
}