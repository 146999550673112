import axios from "axios";

function getDefaultGETHeaders(accessToken = ""){
    return {headers: {
            'content-type': 'application/json',
            'Cache-Control': 'max-age=0',
            Authorization: `Bearer ${accessToken}`
        }}
}

function getComments(articleId){
    return axios
        .get(process.env.VUE_APP_API_BASE_URL + '/comment/'+ articleId,
            getDefaultGETHeaders())
}

function saveSubscriber(req){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/subscriber',
            req,
            getDefaultGETHeaders())
}


function createContact(req){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/contact',
            req,
            getDefaultGETHeaders())
}

function savePageView(req){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/pageView',
            req,
            getDefaultGETHeaders(""))
}

function createCheckout(req){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/checkout',
            req,
            getDefaultGETHeaders(""))
}

function getCheckoutDetails(req){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/checkout/details',
            req,
            getDefaultGETHeaders(""))
}

function getLessonsOfTutorial(tutorialId){
    return axios
        .get(process.env.VUE_APP_API_BASE_URL + '/tutorials/'+tutorialId+'/lessons',
            getDefaultGETHeaders(""))
}

function getProducts(categoryId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/products?categoryId="+categoryId)
}

function getCategories(){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/categories")
}

function getProductByID(productId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/product/"+productId)
}

function addToCart(productId, delta, idToken){
    return axios.put(process.env.VUE_APP_API_BASE_URL+"/me/cart",{
        productId : productId,
        delta: delta
    },{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function getCart(idToken){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/me/cart",{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function checkoutv2(idToken, provider, shippingCountryCode,currency,shippingAddress){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/checkout2",{
        provider : provider,
        shippingCountryCode: shippingCountryCode,
        currency: currency,
        shippingAddress:shippingAddress
    },{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function createAnonymousUser(){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/user/anonymous")
}

function mergeUser(idTokenFirebase, idTokenAnonymous){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/user/merge",null, {
        headers : {
            'Authorization': `Bearer ${idTokenFirebase}`,
            'X-PGL-Anonymous-JWT': `Bearer ${idTokenAnonymous}`

        }
    })
}

function getCheckoutDetails2(idTokenFirebase,req){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/checkout/details2',
            req,
            {
                    headers : {
                        'Authorization': `Bearer ${idTokenFirebase}`,
                    }
                }
        )
}

function redeemSubscriptions(idTokenFirebase,jwtRedeem){
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/redeem',
            {
                "redeemJWT": jwtRedeem
            },
            {
                headers : {
                    'Authorization': `Bearer ${idTokenFirebase}`,
                }
            }
        )
}

function getMyCourses(idToken){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/me/courses",{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function getMyCourseById(idToken, courseID, lessonID){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/me/courses/"+courseID,{
        headers : {
            'Authorization': `Bearer ${idToken}`
        },
        params: {
            lessonId: lessonID
        }
    })
}


function updateMyProgression(idToken, req){
    return axios.put(process.env.VUE_APP_API_BASE_URL+"/me/progression",
        req,
        {
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function getNextSession(idToken, tutorialId, currentSessionId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/me/progression/next?currentSessionId="+currentSessionId+"&tutorialId="+tutorialId,
        {
            headers : {
                'Authorization': `Bearer ${idToken}`
            }
        })
}

function getPreviousSession(idToken, tutorialId, currentSessionId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/me/progression/previous?currentSessionId="+currentSessionId+"&tutorialId="+tutorialId,
        {
            headers : {
                'Authorization': `Bearer ${idToken}`
            }
        })
}

function getOneLessonPublic(tutorialId, lessonId, jwtExcerpt){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/tutorials/${tutorialId}/lessons/${lessonId}`,
        {
            params : {
                jwtExcerpt:jwtExcerpt,
            },
        })
}

function getOneLessonPrivate(tutorialId, lessonId, idToken){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/me/tutorials/${tutorialId}/lessons/${lessonId}`,
        {
            headers : {
                'Authorization': `Bearer ${idToken}`
            }
        })
}

function getExcerpt(tutorialId, email){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/excerpt",
        {
            tutorialId:tutorialId,
            email:email
        },
        {
        })
}


function getHomeData(){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/home/data`,
        {
            headers : {
            }
        })
}

function getPostById(postId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/post?id=`+postId,
        {
            headers : {
            }
        })
}

function getPostList(){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/post/list`,
        {
            headers : {
            }
        })
}

function tracking(data, idToken = ""){
    if (localStorage.getItem("userStatus") === "admin"){
        data.d = true
    }
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/view",data,{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function getQuizzById(postId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/quizz?id=`+postId,
        {
            headers : {
            }
        })
}

function postAnswers(obj){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/answers",
        obj,
        {
        })
}

function triggerChat(req) {
    return axios
        .post(process.env.VUE_APP_API_BASE_URL + '/trigger/chat',
            req,
            {})
}


export default {
    getComments:getComments,
    saveSubscriber:saveSubscriber,
    createContact:createContact,
    savePageView:savePageView,
    createCheckout:createCheckout,
    getCheckoutDetails:getCheckoutDetails,
    getLessonsOfTutorial:getLessonsOfTutorial,
    getProducts:getProducts,
    getCategories:getCategories,
    getProductByID:getProductByID,
    addToCart: addToCart,
    getCart:getCart,
    checkoutv2:checkoutv2,
    createAnonymousUser:createAnonymousUser,
    mergeUser:mergeUser,
    getCheckoutDetails2:getCheckoutDetails2,
    redeemSubscriptions:redeemSubscriptions,
    getMyCourses:getMyCourses,
    getMyCourseById:getMyCourseById,
    updateMyProgression:updateMyProgression,
    getNextSession:getNextSession,
    getPreviousSession:getPreviousSession,
    getOneLessonPublic: getOneLessonPublic,
    getOneLessonPrivate: getOneLessonPrivate,
    getExcerpt:getExcerpt,
    getHomeData:getHomeData,
    getPostById: getPostById,
    getPostList: getPostList,
    tracking:tracking,
    getQuizzById : getQuizzById,
    postAnswers: postAnswers,
    triggerChat:triggerChat,

}