import Vue from 'vue'
import VueRouter from 'vue-router'
import Consts from "@/consts";
import BookRoutes from './bookRoutes'
import Webservice from "@/webservice";

Vue.use(VueRouter)

const routes = [
    ...BookRoutes,
  {
    path: '/',
    name: Consts.ROUTE_NAMES.HOME,
    component:  () => import( '../views/Home.vue')
  },
  {
    path: '/foreword',
    name: Consts.ROUTE_NAMES.FOREWORD,
    component: () => import( '../views/Foreword.vue')
  },
  {
    path: '/legal',
    name: Consts.ROUTE_NAMES.LEGAL,
    component: () => import( '../views/Legal.vue')
  },
  {
    path: '/the-author',
    name: Consts.ROUTE_NAMES.THE_AUTHOR,
    component: () => import( '../views/TheAuthor.vue')
  },
  {
    path: '/buy-digital-or-hard-copy',
    name: Consts.ROUTE_NAMES.BUY,
    component: () => import( '../views/Buy.vue')
  },
{
    path: '/feedback',
    name: Consts.ROUTE_NAMES.FEEDBACK,
    component: () => import( '../views/Feedback.vue')
  },
{
    path: '/success',
    name: Consts.ROUTE_NAMES.SUCCESS,
    component: () => import( '../views/Success.vue')
  },
{
    path: '/ask-coupon',
    name: Consts.ROUTE_NAMES.ASK_COUPON,
    component: () => import( '../views/AskCoupon.vue')
  },
{
    path: '/terms-and-conditions',
    name: Consts.ROUTE_NAMES.TERMS_CONDITIONS,
    component: () => import( '../views/TermsAndConditions.vue')
  },
{
    path: '/changelog',
    name: Consts.ROUTE_NAMES.CHANGELOG,
    component: () => import( '../views/Changelog.vue')
  },
  {
    path: '/checkout/paypal',
    name: Consts.ROUTE_NAMES.CHECKOUT_PAYPAL,
    component: () => import( '../views/CheckoutPaypal.vue')
  },
  {
    path: '/subscribe',
    name: Consts.ROUTE_NAMES.SUBSCRIBE,
    component: () => import( '../views/Subscribe.vue')
  },

  {
    path: '/tutorial/:slug(.*)-:tutorialId([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})',
    name: Consts.ROUTE_NAMES.TUTORIAL,
    component: () => import('../views/VideoPresentation.vue'),
    props: route =>({
      tutorialId: route.params.tutorialId,
      slug: route.params.slug,
    })
  },
  {
    path: '/post/:slug(.*)-:postId([a-z0-9]{20})',
    name: Consts.ROUTE_NAMES.POST_DETAIL,
    component: () => import('../views/PostDetail.vue'),
    props: route =>({
      postId: route.params.postId,
      slug: route.params.slug,
    })
  },
  {
    path: '/posts',
    name: Consts.ROUTE_NAMES.POSTS,
    component: () => import('../views/PostList.vue'),
  },
  {
    path: '/login',
    name: Consts.ROUTE_NAMES.LOGIN,
    component: () => import('../views/Login.vue')
  },
  {
    path: '/cart',
    name: Consts.ROUTE_NAMES.CART,
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/products',
    name: Consts.ROUTE_NAMES.PRODUCTS,
    component: () => import('../views/Products.vue')
  },
  {
    path: '/product/:slug(.*)--:productId([a-z0-9]{20})',
    name: Consts.ROUTE_NAMES.PRODUCT_DETAIL,
    component: () => import('../views/ProductDetail.vue'),
    props: route =>({
      productId: route.params.productId,
      slug: route.params.slug,
    })
  },
  {
    // login step in checkout
    path: '/checkout/login',
    name: Consts.ROUTE_NAMES.CHECKOUT_LOGIN,
    component: () => import('../views/FunnelLogin.vue'),
  },
  {
    // pay step in checkout
    path: '/checkout/pay',
    name: Consts.ROUTE_NAMES.CHECKOUT_PAY,
    component: () => import('../views/FunnelPay.vue'),
  },
  {
    // pay step in checkout
    path: '/redeem',
    name: Consts.ROUTE_NAMES.REDEEM,
    component: () => import('../views/Redeem.vue'),
  },
  {
    // pay step in checkout
    path: '/redeem/success',
    name: Consts.ROUTE_NAMES.REDEEM_SUCCESS,
    component: () => import('../views/RedeemSuccess.vue'),
  },
  {
    // pay step in checkout
    path: '/my-courses',
    name: Consts.ROUTE_NAMES.MY_COURSES,
    component: () => import('../views/MyCourses.vue'),
  },
  {
    path: '/course/:id',
    name: Consts.ROUTE_NAMES.COURSE_DETAIL,
    component: () => import('../views/CourseDetail.vue'),
    props: route =>({ id: route.params.id, lessonId: route.query.lessonId})
  },
  {
    path: '/course/:tutorialId/lesson/:lessonId/preview',
    name: Consts.ROUTE_NAMES.LESSON_PREVIEW,
    component: () => import('../views/LessonPreview.vue'),
    props: route =>({ tutorialId: route.params.tutorialId,lessonId:route.params.lessonId})
  },
    // nb hardcoded due to bit ly link not modifiable....
    // bit.ly/pgl-serverless3
  {
    path: '/erratum',
    name: Consts.ROUTE_NAMES.ERRATUM,
    redirect: '/course/a6f0e242-0cbf-4623-86f3-fbe57e10d80a?lessonId=a4178e7f-b8f9-48ac-b7d7-b9b12d6aabfd',
  },
  {
    path: '/unsubscribe-success',
    name: Consts.ROUTE_NAMES.UNSUBSCRIBE_NL,
    component: () => import('../views/UnsubscribeNl.vue'),
  },
    // /quizz/ces7em3n5aqc70ek70hg
  {
    path: '/quizz/:id',
    name: Consts.ROUTE_NAMES.QUIZZ_VIEW,
    component: () => import('../views/QuizzView.vue'),
    props: route =>({ id: route.params.id})

  },

  {
    path: '/cours-de-code-paris',
    name: Consts.ROUTE_NAMES.CODE_COURSE,
    component: () => import('../views/CodeCourse.vue'),

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  Webservice.tracking({
    pageView:{
      referrer:document.referrer,
      lang: navigator.language || navigator.userLanguage,
      to:to.path,
      from:from.path,
      utm: {
        source: to.query.utm_source,
        medium: to.query.utm_medium,
        campaign: to.query.utm_campaign,
        term: to.query.utm_term,
        content: to.query.utm_content,
      }
    }
  })
})

export default router