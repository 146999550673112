
export default [
	
		
		  {
			path: '/chap-1-programming-a-computer',
			name: 'Chap1ProgrammingAComputer',
			component: () => import('../views/Chap1ProgrammingAComputer.vue')
		  },
		
  
		
		  {
			path: '/chap-2-the-go-language',
			name: 'Chap2TheGoLanguage',
			component: () => import('../views/Chap2TheGoLanguage.vue')
		  },
		
  
		
		  {
			path: '/chap-3-the-terminal',
			name: 'Chap3TheTerminal',
			component: () => import('../views/Chap3TheTerminal.vue')
		  },
		
  
		
		  {
			path: '/chap-4-setup-your-dev-environment',
			name: 'Chap4SetupYourDevEnvironment',
			component: () => import('../views/Chap4SetupYourDevEnvironment.vue')
		  },
		
  
		
		  {
			path: '/chap-5-first-go-application',
			name: 'Chap5FirstGoApplication',
			component: () => import('../views/Chap5FirstGoApplication.vue')
		  },
		
  
		
		  {
			path: '/chap-6-binary-and-decimal',
			name: 'Chap6BinaryAndDecimal',
			component: () => import('../views/Chap6BinaryAndDecimal.vue')
		  },
		
  
		
		  {
			path: '/chap-7-hexadecimal-octal-ascii-utf8-unicode-runes',
			name: 'Chap7HexadecimalOctalAsciiUtf8UnicodeRunes',
			component: () => import('../views/Chap7HexadecimalOctalAsciiUtf8UnicodeRunes.vue')
		  },
		
  
		
		  {
			path: '/chap-8-variables-constants-and-basic-types',
			name: 'Chap8VariablesConstantsAndBasicTypes',
			component: () => import('../views/Chap8VariablesConstantsAndBasicTypes.vue')
		  },
		
  
		
		  {
			path: '/chap-9-control-statements',
			name: 'Chap9ControlStatements',
			component: () => import('../views/Chap9ControlStatements.vue')
		  },
		
  
		
		  {
			path: '/chap-10-functions',
			name: 'Chap10Functions',
			component: () => import('../views/Chap10Functions.vue')
		  },
		
  
		
		  {
			path: '/chap-11-packages-and-imports',
			name: 'Chap11PackagesAndImports',
			component: () => import('../views/Chap11PackagesAndImports.vue')
		  },
		
  
		
		  {
			path: '/chap-12-package-initialization',
			name: 'Chap12PackageInitialization',
			component: () => import('../views/Chap12PackageInitialization.vue')
		  },
		
  
		
		  {
			path: '/chap-13-types',
			name: 'Chap13Types',
			component: () => import('../views/Chap13Types.vue')
		  },
		
  
		
		  {
			path: '/chap-14-methods',
			name: 'Chap14Methods',
			component: () => import('../views/Chap14Methods.vue')
		  },
		
  
		
		  {
			path: '/chap-15-pointer-type',
			name: 'Chap15PointerType',
			component: () => import('../views/Chap15PointerType.vue')
		  },
		
  
		
		  {
			path: '/chap-16-interfaces',
			name: 'Chap16Interfaces',
			component: () => import('../views/Chap16Interfaces.vue')
		  },
		
  
		
		  {
			path: '/chap-17-go-modules',
			name: 'Chap17GoModules',
			component: () => import('../views/Chap17GoModules.vue')
		  },
		
  
		
		  {
			path: '/chap-18-go-module-proxies',
			name: 'Chap18GoModuleProxies',
			component: () => import('../views/Chap18GoModuleProxies.vue')
		  },
		
  
		
		  {
			path: '/chap-19-unit-tests',
			name: 'Chap19UnitTests',
			component: () => import('../views/Chap19UnitTests.vue')
		  },
		
  
		
		  {
			path: '/chap-20-arrays',
			name: 'Chap20Arrays',
			component: () => import('../views/Chap20Arrays.vue')
		  },
		
  
		
		  {
			path: '/chap-21-slices',
			name: 'Chap21Slices',
			component: () => import('../views/Chap21Slices.vue')
		  },
		
  
		
		  {
			path: '/chap-22-maps',
			name: 'Chap22Maps',
			component: () => import('../views/Chap22Maps.vue')
		  },
		
  
		
		  {
			path: '/chap-23-errors',
			name: 'Chap23Errors',
			component: () => import('../views/Chap23Errors.vue')
		  },
		
  
		
		  {
			path: '/chap-24-anonymous-functions-and-closures',
			name: 'Chap24AnonymousFunctionsAndClosures',
			component: () => import('../views/Chap24AnonymousFunctionsAndClosures.vue')
		  },
		
  
		
		  {
			path: '/chap-25-json-and-xml',
			name: 'Chap25JsonAndXml',
			component: () => import('../views/Chap25JsonAndXml.vue')
		  },
		
  
		
		  {
			path: '/chap-26-basic-http-server',
			name: 'Chap26BasicHttpServer',
			component: () => import('../views/Chap26BasicHttpServer.vue')
		  },
		
  
		
		  {
			path: '/chap-27-enum-iota-and-bitmask',
			name: 'Chap27EnumIotaAndBitmask',
			component: () => import('../views/Chap27EnumIotaAndBitmask.vue')
		  },
		
  
		
		  {
			path: '/chap-28-dates-and-time',
			name: 'Chap28DatesAndTime',
			component: () => import('../views/Chap28DatesAndTime.vue')
		  },
		
  
		
		  {
			path: '/chap-29-data-storage-files-and-databases',
			name: 'Chap29DataStorageFilesAndDatabases',
			component: () => import('../views/Chap29DataStorageFilesAndDatabases.vue')
		  },
		
  
		
		  {
			path: '/chap-30-concurrency',
			name: 'Chap30Concurrency',
			component: () => import('../views/Chap30Concurrency.vue')
		  },
		
  
		
		  {
			path: '/chap-31-logging',
			name: 'Chap31Logging',
			component: () => import('../views/Chap31Logging.vue')
		  },
		
  
		
		  {
			path: '/chap-32-templates',
			name: 'Chap32Templates',
			component: () => import('../views/Chap32Templates.vue')
		  },
		
  
		
		  {
			path: '/chap-33-application-configuration',
			name: 'Chap33ApplicationConfiguration',
			component: () => import('../views/Chap33ApplicationConfiguration.vue')
		  },
		
  
		
		  {
			path: '/chap-34-benchmarks',
			name: 'Chap34Benchmarks',
			component: () => import('../views/Chap34Benchmarks.vue')
		  },
		
  
		
		  {
			path: '/chap-35-build-an-http-client',
			name: 'Chap35BuildAnHttpClient',
			component: () => import('../views/Chap35BuildAnHttpClient.vue')
		  },
		
  
		
		  {
			path: '/chap-36-program-profiling',
			name: 'Chap36ProgramProfiling',
			component: () => import('../views/Chap36ProgramProfiling.vue')
		  },
		
  
		
		  {
			path: '/chap-37-context',
			name: 'Chap37Context',
			component: () => import('../views/Chap37Context.vue')
		  },
		
  
		
		  {
			path: '/chap-38-generics',
			name: 'Chap38Generics',
			component: () => import('../views/Chap38Generics.vue')
		  },
		
  
		
		  {
			path: '/chap-39-an-object-oriented-programming-language',
			name: 'Chap39AnObjectOrientedProgrammingLanguage',
			component: () => import('../views/Chap39AnObjectOrientedProgrammingLanguage.vue')
		  },
		
  
		
			{
			path: '/chap-38-an-object-oriented-programming-language',
			redirect: '/chap-39-an-object-oriented-programming-language',
		  },
		
  
		
		  {
			path: '/chap-40-upgrading-or-downgrading-go',
			name: 'Chap40UpgradingOrDowngradingGo',
			component: () => import('../views/Chap40UpgradingOrDowngradingGo.vue')
		  },
		
  
		
			{
			path: '/chap-39-upgrading-or-downgrading-go',
			redirect: '/chap-40-upgrading-or-downgrading-go',
		  },
		
  
		
		  {
			path: '/chap-41-design-recommendations',
			name: 'Chap41DesignRecommendations',
			component: () => import('../views/Chap41DesignRecommendations.vue')
		  },
		
  
		
			{
			path: '/chap-40-design-recommendations',
			redirect: '/chap-41-design-recommendations',
		  },
		
  
		
		  {
			path: '/chap-42-cheatsheet',
			name: 'Chap42Cheatsheet',
			component: () => import('../views/Chap42Cheatsheet.vue')
		  },
		
  
		
			{
			path: '/chap-41-cheatsheet',
			redirect: '/chap-42-cheatsheet',
		  },
		
  
]