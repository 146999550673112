<template>
  <div>
      <router-view/>
  </div>
</template>

<style>
</style>
<script>
import ScriptLoader from "@/scriptLoader";

export default {
  components: {},
  created() {
    ScriptLoader.loadCrisp()
  }
}
</script>